import React, { ReactElement, PropsWithChildren, useRef, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import TopNavigation from './components/TopNavigation';
import Footer from './components/Footer';

const Wrapper = ({ children }: PropsWithChildren): ReactElement => {
  const headerRef = useRef<HTMLElement>(null);
  const footerRef = useRef<HTMLElement>(null);
  const [headerHeight, setHeaderHeight] = useState(0);
  const [footerHeight, setFooterHeight] = useState(0);

  useEffect(() => {
    setHeaderHeight(headerRef.current?.offsetHeight ?? 0);
    setFooterHeight(footerRef.current?.offsetHeight ?? 0);
  }, []);

  return (
    <Box>
      <TopNavigation ref={headerRef} />

      <Box minHeight={`calc(100vh - ${footerHeight + headerHeight}px)`} mt={`${headerHeight}px`}>
        {children}
      </Box>

      <Footer ref={footerRef} />
    </Box>
  );
};

export default Wrapper;
